export const getCurrentDate = () => {
    let currentDate = new Date()
    const day = currentDate.getDay()
    const weekday = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday']
    const month = new Intl.DateTimeFormat('en', { month: 'short' }).format(currentDate)
    const date = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(currentDate)
    return `${weekday[day]}, ${month} ${date}`
}

export const getDate = (d) => {
    let date = new Date(d)
    let month = date.getMonth() + 1
    month = month < 10 ? '0' + month : month

    let dd = date.getDate()
    dd = dd < 10 ? '0' + dd : dd

    return date.getFullYear() + '-' + month + '-' + dd
}
