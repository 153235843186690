import React from 'react'
import DataGrid from 'components/DataTable/DataGridWrapper'
import { getDate } from 'use/Helper'
import { ConditionByProfileIdAPI } from 'services/Services'
// Spinning circle loading animation
import Loader from 'components/Loader/Loader'
// Popup with patient information
import AlertDialog from 'components/AlertDialog'
import { useSearchField } from 'hoc/SearchFieldProvider'
import filterEntries from 'util/filterEntries'
import { IconButton } from '@mui/material'
import { ToolTipIcon } from 'assets/svg/tooltipIcon'

class ConditionsImpl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            open: false,
            selected: {},
            loading: true,
        }
    }

    componentDidMount() {
        const { setSearchColumns, selectedDependent } = this.props
        this.conditionByProfileId(selectedDependent?.id)
        setSearchColumns([
            {
                value: 'providerName',
                label: 'Provider name',
            },
            {
                value: 'patient',
                label: 'Patient name',
            },
            {
                value: 'category',
                label: 'Category',
            },
            {
                value: 'dateRecorded',
                label: 'Date',
            },
        ])
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedDependent } = this.props
        if (selectedDependent?.id !== prevProps.selectedDependent?.id) {
            this.conditionByProfileId(selectedDependent?.id)
        }
    }
    // Returns patient conditions from Matchrite API
    conditionByProfileId = async (selectedDependentId) => {
        let profileid = selectedDependentId || localStorage.getItem('profileid')
        this.setState({
            loading: true,
        })
        const data = await ConditionByProfileIdAPI(profileid)

        if (data) {
            if (data.message === 'No Data Found') {
                this.setState({ value: [], loading: false })
            } else {
                this.setState({ value: data.condition, loading: false })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    getFilteredEntries = () => {
        const { value } = this.state
        const { searchTerm, filterByColumn } = this.props
        const allColumns = ['providerName', 'patient', 'dateRecorded', 'onsetDateTime', 'category']
        const columns = filterByColumn === 'all' ? allColumns : [filterByColumn]
        return filterEntries(value, columns, searchTerm)
    }

    render() {
        // Spinning circle loading animation
        if (this.state.loading) {
            return <Loader pageName="conditions" />
        }

        // Columns for data grid that will that will display patient conditions
        const columns = [
            {
                field: 'providerName',
                headerName: 'Provider name',
                flex: 0.5,
            },
            {
                field: 'patient',
                headerName: 'Patient name',
                flex: 0.5,
            },
            {
                field: 'category',
                headerName: 'Category',
                flex: 0.5,
            },

            // {
            //     field: 'onsetDateTime',
            //     headerName: 'On set date',
            //     valueFormatter: (params) => getDate(params.value),
            //     type: 'date',
            //     flex: 0.5,
            //     renderCell: (param) => (param.value ? getDate(param.value) : ''),
            // },
            {
                field: 'code',
                headerName: 'Code (details)',
                flex: 0.5,
            },
            {
                field: 'dateRecorded',
                headerName: 'Date generated',
                valueFormatter: (params) => getDate(params.value),
                type: 'date',
                flex: 0.5,
                renderCell: (param) => (param.value ? param.value : ''),
            },
            // {
            //     field: 'category',
            //     headerName: 'Category',
            //     flex: 0.5,
            // },
            {
                field: '',
                headerName: 'Click icon for details',
                flex: 0.2,
                sortable: false,
                disableColumnMenu: true,
                // Icon clicked to display consolidated information about data row
                renderCell: (param) => (
                    <IconButton
                        onClick={() =>
                            this.setState({
                                selected: param.row,
                                open: true,
                            })
                        }
                    >
                        <ToolTipIcon />
                    </IconButton>
                ),
            },
        ]

        return (
            <div className="container-right">
                {/* Data grid that populates cells with patient condition data */}
                <div className="white-container">
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            rows={this.getFilteredEntries()}
                            disableSelectionOnClick
                            getRowId={(r) => JSON.stringify(r)}
                        />
                    </div>

                    {/* Popup that displays information about a condition row when the icon in it's furthest right column is clicked */}
                    {Object.keys(this.state.selected).length > 0 && (
                        <AlertDialog
                            fullWidth
                            title="Condition details"
                            open={this.state.open}
                            setOpen={(value) => this.setState({ open: value })}
                        >
                            {/* <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <label className="fw-bold">Severity:</label> {this.state.selected.providerName}
                            </div> */}
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Provider names: </label>{' '}
                                {this.state.selected.providerName || '-'}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Patient: </label> {this.state.selected.patient || '-'}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Clinical status: </label>{' '}
                                {this.state.selected.clinicalStatus || '-'}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Onset Date: </label>{' '}
                                {this.state.selected.onsetStartDateTime + '-' + this.state.selected.onsetEndDateTime}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Encounter: </label> {this.state.selected.encounter || '-'}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Body Site: </label> {this.state.selected.bodySite || '-'}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Onset String: </label>{' '}
                                {this.state.selected.onsetString || '-'}
                            </div>
                        </AlertDialog>
                    )}
                </div>
            </div>
        )
    }
}

export function Conditions(props) {
    const { searchTerm, filterByColumn, setSearchColumns, selectedDependent } = useSearchField()

    return (
        <ConditionsImpl
            {...props}
            searchTerm={searchTerm}
            filterByColumn={filterByColumn}
            setSearchColumns={setSearchColumns}
            selectedDependent={selectedDependent}
        ></ConditionsImpl>
    )
}

export default Conditions
