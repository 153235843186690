import React from 'react'

export const pageTitles = {
    myprofile: 'My Profile',
    importrecords: 'Import Records',
    'share-records': 'Share My Records',
    dependents: 'Dependents',
    encounters: 'Encounters',
    vitals: 'Vitals',
    medications: 'Medications',
    // treatmentplan: 'Care Plan',
    procedures: 'Procedures',
    conditions: 'Conditions',
    drvisitnotes: 'Dr Visit Notes',
    labs: 'Labs',
    immunization: 'Immunization',
    yourclaims: 'Your Claims',
    // supportPage: 'User Support',
    privacypolicy: 'User Policy Terms & Conditions',
    covidpassport: 'Covid Passport',
    addyourdoctor: 'Add Your Doctor',
    addOrganization: 'Add Organization',
    'doctor-visits': 'Doctor Visits',
    viewxray: 'View Imaging',
    veteranhistory: 'Veteran History',
    disability: 'Veteran Disability Rating',
    myrecords: 'My Records',
    bookappointment: 'Book Appointment Request',
    allergies: 'Allergies',
}

export const matchPageTitles = {
    '/home/book/': 'bookappointment',
    '/home/myrecords/vitals': 'vitals',
    '/home/myrecords/medications': 'medications',
    '/home/myrecords/treatmentplan': 'treatmentplan',
    '/home/myrecords/procedures': 'procedures',
    '/home/myrecords/conditions': 'conditions',
    '/home/myrecords/labs': 'labs',
    '/home/myrecords/immunization': 'immunization',
    '/home/myrecords/allergies': 'allergies',
    '/home/myrecords/drvisitnotes': 'drvisitnotes',
}

export const showSearchFieldInPages = (currentPath) => {
    return [
        // '/home/drvisitnotes',
        '/home/myrecords',
        '/home/yourclaims',
    ].some((path) => (new RegExp(path, "i")).test(currentPath))
}

export const pageSubTitles = {
    myprofile: <span>Here you can update your personal credentials such as <br/> patient info, insurance info and share records.</span>,
    importrecords: <span></span>,
    // importrecords: <span>Here you can import your existing medical records that you think <br/> we should know about to get better health support.</span>,
    'share-records': '',
    encounters: '',
    vitals: '',
    medications: '',
    treatmentplan: '',
    procedures: '',
    conditions: '',
    drvisitnotes: '',
    labs: '',
    immunization: '',
    yourclaims: 'Here you can see your claims.',
    covidpassport: '',
    addyourdoctor: '',
    addOrganization: '',
    'doctor-visits': '',
    viewxray: '',
    veteranhistory: '',
    disability: '',
    myrecords: 'Here you can see your clinical records.',
    bookappointment: 'Here you can book an appointment with your doctor.',
}
