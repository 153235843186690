import config from 'use/Config'
import mrcFetch from 'util/authFetch'

export function cernerPatientToken(EHRClientId, EHRGetTokenUrl) {
    let tokenFetchUrl = ''
    let HospitalClientId = ''
    const search = window.location.search
    const params = new URLSearchParams(search)
    const code = params.get('code')
    const state_hospital = params.get('state')
    tokenFetchUrl = EHRGetTokenUrl
    HospitalClientId = EHRClientId

    let myHeaders = new Headers()
    myHeaders.append('content-type', 'application/x-www-form-urlencoded')
    let urlencoded = new URLSearchParams()
    urlencoded.append('client_id', HospitalClientId)
    urlencoded.append('grant_type', 'authorization_code')
    urlencoded.append('code', code)
    urlencoded.append('redirect_uri', config.cernerCredentials.REDIRECT.REDIRECT_URI)

    let requestOptions = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'follow',
    }

    fetch(tokenFetchUrl, requestOptions)
        .then((response) => response.json())
        .then((result) => {
            let datatok = result.access_token
            let patient = result.patient
            let ehrhospital = state_hospital

            addPatientToCerner(datatok, patient, ehrhospital)
        })
        .catch((error) => console.log('error', error))
}

export function addPatientToCerner(code, state) {
    // export function addPatientToCerner(token, patientId, ehrhospital) {
    // const patientId='evYS76EX6LLeCX2PlRLhvwg3';
    // const search = window.location.search
    // const params = new URLSearchParams(search)
    // const code = params.get('code')
    // const ehrhospital = params.get('state')
    let raw = JSON.stringify({
        token: code,
        // token: token,
        // patientId: patientId,
        // ProfileId: localStorage.getItem('profileid'),
        ProfileId: localStorage.getItem('selectedProfileId'),
        EHRHospital: state,
    })
    let requestOptions = {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json',
        },
        body: raw,
        redirect: 'follow',
    }
    return mrcFetch(`${config.mrc.baseApiUrl}/Cerner/ProcessCernerData`, requestOptions)
        .then((response) => response.text())
        .then((result) => localStorage.removeItem('selectedProfileId'))
    // .catch((error) => console.log('error', error))
}

// @TODO: Confirm this function is required
// export function BookAppointment() {
//     let myHeaders = new Headers()
//     myHeaders.append('Content-Type', 'application/json')

//     let raw = JSON.stringify({
//         PatientId: '16',
//         ProviderId: '6',
//         TypeId: '1',
//         StartDateTime: '2020-11-17 09:01:43.000',
//         problemDefinition: 'Headache',
//         UserId: '14',
//     })

//     let requestOptions = {
//         method: 'POST',
//         headers: myHeaders,
//         body: raw,
//         redirect: 'follow',
//     }

//     fetch(
//         'https://qqp59v2ldd.execute-api.us-east-2.amazonaws.com/Prod/api/Appointments/BookAppointment',
//         requestOptions
//     )
//         .then((response) => response.text())
//         .then((result) => console.log(result))
//         .catch((error) => console.log('error', error))
// }
