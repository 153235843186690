import React from 'react'
import DataGrid from 'components/DataTable/DataGridWrapper'

import { getDate } from 'use/Helper'
import Loader from 'components/Loader/Loader'
import { DocumentReferenceByProfileIdAPI } from 'services/Services'

// Custom button element that accepts the ID of a doctor visit note as a parameter, and then displays the actual note after recieving it from an api request
import CcdaHelper from './CcdaHelper'
import { useSearchField } from 'hoc/SearchFieldProvider'
import filterEntries from 'util/filterEntries'
import AlertDialog from 'components/AlertDialog'

import { DependentService } from 'services/dependent.service'
import { useEffect } from 'react'
import { ToolTipIcon } from 'assets/svg/tooltipIcon'
import { IconButton } from '@mui/material'

class DrvVisitNotesImpl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            open: false,
            selected: {},
            loading: true,
        }
    }

    componentDidMount() {
        const { setSearchColumns, selectedDependent } = this.props
        this.documentReferenceByProfileId(selectedDependent?.id)
        setSearchColumns([
            {
                value: 'providerName',
                label: 'Provider',
            },
            {
                value: 'subject',
                label: 'Patient name',
            },
            {
                value: 'type',
                label: 'Type',
            },
            {
                value: 'dateGenerated',
                label: 'Date',
            },
        ])
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedDependent } = this.props
        if (selectedDependent?.id !== prevProps.selectedDependent?.id) {
            this.documentReferenceByProfileId(selectedDependent?.id)
        }
    }

    // Function that returns list of patient's doctor visit notes from matchrite api (doesn't have the note itself, just an ID that can be used for a separate api request)
    documentReferenceByProfileId = async (selectedDependentId) => {
        const profileId = selectedDependentId || localStorage.getItem('profileid')
        this.setState({
            loading: true,
        })
        const data = await DocumentReferenceByProfileIdAPI(profileId)

        if (data) {
            if (data.message === 'No Data Found') {
                this.setState({ value: [], loading: false })
            } else {
                this.setState({
                    value: data.documentReference,
                    loading: false,
                })
            }
        } else {
            this.setState({ loading: false })
        }

        // console.log(this.state.value)
    }

    // Function to download doctor visit notes as a CSV (Isn't actually called anywhere)
    exportDVisits = () => {
        if (this.state.value.length === 0) {
            return
        }

        const con = []

        let csvContent = 'data:text/csvcharset=utf-8,'

        Object.keys(this.state.value).forEach((data) => {
            const val = this.state.value[data]
            con.push(val)
        })

        const title = `Facility, Patient name, Date generated, Type`
        csvContent += title + '\r\n'

        con.forEach(function (rowArray) {
            const row =
                '"' +
                rowArray.providerName +
                '","' +
                rowArray.subject +
                '","' +
                rowArray.indexedOn +
                '","' +
                rowArray.type +
                '"'
            csvContent += row + '\r\n'
        })

        csvContent = csvContent.replace(/#/g, '')
        const encodedUri = encodeURI(csvContent)
        const link = document.createElement('a')
        link.setAttribute('href', encodedUri)

        const d = new Date()
        const ye = new Intl.DateTimeFormat('en', { year: 'numeric' }).format(d)
        const mo = new Intl.DateTimeFormat('en', { month: '2-digit' }).format(d)
        const da = new Intl.DateTimeFormat('en', { day: '2-digit' }).format(d)
        const _d = `${mo}-${da}-${ye}`

        link.setAttribute('download', `Exported on ${_d}.csv`)

        document.body.appendChild(link)
        link.click()
    }

    getFilteredEntries = () => {
        const { value } = this.state
        const { searchTerm, filterByColumn } = this.props
        const allColumns = ['providerName', 'subject', 'type']
        const columns = filterByColumn === 'all' ? allColumns : [filterByColumn]
        return filterEntries(value, columns, searchTerm)
    }

    render() {
        if (this.state.loading) {
            return <Loader pageName="drvisitnotes" />
        }

        // Columns for data grid listing doctor visit notes
        const columns = [
            {
                field: 'providerName',
                headerName: 'Provider',
                flex: 0.5,
            },
            {
                field: '',
                headerName: 'Author',
                flex: 0.5,
                renderCell: (params) => params.row.author?.map((author) => author.display).join(','),
            },
            {
                field: 'subject',
                headerName: 'Patient name',
                flex: 0.5,
            },
            {
                field: '',
                headerName: 'Category',
                flex: 0.5,
                renderCell: (params) => params.row.category?.map((category) => category.text).join(','),
            },
            {
                // Doesn't currently display anything
                field: 'dateGenerated',
                headerName: 'Date generated',
                type: 'date',
                flex: 0.5,
                valueFormatter: (params) => getDate(params.value),
                renderCell: (params) => (params.value ? getDate(params.value) : ''),
            },
            // {
            //     field: 'type',
            //     headerName: 'Type',
            //     flex: 0.5,
            // },
            {
                // Cells contain button to view popup containing the actual doctor visit note
                field: 'id',
                headerName: 'View document',
                flex: 0.5,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (params) => <CcdaHelper docid={params.value} />,
            },

            {
                field: '',
                headerName: '',
                flex: 0.2,
                sortable: false,
                disableColumnMenu: true,
                // Icon clicked to display consolidated information about data row
                renderCell: (param) => (
                    <IconButton
                        onClick={() =>
                            this.setState({
                                selected: param.row,
                                open: true,
                            })
                        }
                    >
                        <ToolTipIcon />
                    </IconButton>
                ),
            },
        ]

        return (
            <div className="container-right">
                {/* Data grid that populates cells with list of dr visit notes (limit 5 rows per page)*/}
                <div className="white-container">
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            rows={this.getFilteredEntries()}
                            disableSelectionOnClick
                        />
                    </div>
                    {Object.keys(this.state.selected).length > 0 && (
                        <AlertDialog
                            fullWidth
                            title="Dr Visit Details"
                            open={this.state.open}
                            setOpen={(value) => this.setState({ open: value })}
                        >
                            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <label className="fw-bold">Status:</label> {this.state.selected.status || '-'}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Custodian:</label> {this.state.selected.custodian || '-'}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Descriptions:</label>{' '}
                                {this.state.selected.description || '-'}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Type:</label> {this.state.selected.type || '-'}
                            </div>
                        </AlertDialog>
                    )}
                </div>
            </div>
        )
    }
}

export function DrvVisitNotes(props) {
    const { searchTerm, filterByColumn, setSearchColumns, selectedDependent } = useSearchField()
    return (
        <DrvVisitNotesImpl
            {...props}
            searchTerm={searchTerm}
            filterByColumn={filterByColumn}
            setSearchColumns={setSearchColumns}
            selectedDependent={selectedDependent}
        ></DrvVisitNotesImpl>
    )
}

export default DrvVisitNotes
