import React, { useEffect, useState } from 'react'
import DataGrid from 'components/DataTable/DataGridWrapper'
import CancelIcon from '@mui/icons-material/Cancel'
import EditIcon from '@mui/icons-material/Edit'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { DialogContentText, Stack } from '@mui/material'
// import FormGroupHeader from 'components/FormGroupHeader'
import { DependentService } from 'services/dependent.service'
import popupNotification from 'components/Notification/popupNotification'
import Loader from 'components/Loader/Loader'
import moment from 'moment'
import LibraryAddOutlinedIcon from '@mui/icons-material/LibraryAddOutlined'
import AddDependentModal from './AddDependentModal'
import { useSearchField } from 'hoc/SearchFieldProvider'

import './Dependants.scss'

const Dependants = () => {
    const { getDependents } = useSearchField()
    const [open, setOpen] = useState(false)
    const [dependentId, setDependentId] = useState(null)
    const [loader, setLoader] = useState(false)
    const [dependents, setDependents] = useState([])
    const [selectedDependent, setSelectedDependent] = useState({})
    const [showAddDialog, setShowAddDialog] = useState(false)

    const handleOnRemove = (id) => {
        setDependentId(id)
        setOpen(true)
    }

    const handleOnEdit = (row) => {
        setSelectedDependent(row)
        setShowAddDialog(true)
    }

    const handleCloseModal = () => {
        setDependentId(null)
        setOpen(false)
    }
    const columns = [
        {
            field: 'firstName',
            headerName: 'First Name',
            renderCell: (params) => params.row.firstName,
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            renderCell: (params) => params.row.lastName,
        },
        {
            field: 'gender',
            headerName: 'Gender',
            renderCell: (params) => params.row.gender,
        },
        {
            field: 'relationship',
            headerName: 'Relationship',
            renderCell: (params) => params.row.relationship,
        },

        {
            field: 'birthDate',
            headerName: 'Birth Date',
            renderCell: (params) => moment(moment(params.row.birthDate)).format('DD MMM, YYYY'),
        },
        // {
        //     field: 'preferredLanguage',
        //     headerName: 'Preferred Language',
        //     renderCell: (params) => params.row.preferredLanguage,
        // },
        // Column with clickable icon for patient to delete dependent
        {
            field: 'remove',
            headerName: 'Action',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <>
                    <EditIcon className="edit-icon" onClick={() => handleOnEdit(params.row)} />
                    <CancelIcon className="close-icon" onClick={() => handleOnRemove(params.row.id)} />
                </>
            ),
        },
    ]

    const getAllDependents = async (loader = true) => {
        if (loader) setLoader(true)
        try {
            const { data: result } = await DependentService.getAllDependents()
            if (result) {
                setDependents(result)
            }
            if (loader) setLoader(false)
        } catch (error) {
            console.log('something went wrong, error', error)
            setLoader(false)
        }
    }

    const handleDelete = async (id) => {
        setLoader(true)
        try {
            const response = await DependentService.removeDependent(id)
            handleCloseModal()
            popupNotification(response.message || response?.data?.message, true)
            getAllDependents(false)
            getDependents()
            setLoader(false)
        } catch (error) {
            setLoader(false)
            popupNotification(error?.message, false)
        }
    }

    const handleAdd = async (item) => {
        setLoader(true)
        try {
            const { data: response } = selectedDependent
                ? await DependentService.updateDependent({ dependentId: selectedDependent?.id, ...item })
                : await DependentService.addDependent(item)
            if (response) {
                popupNotification(response.message || response?.data?.message, true)
                getAllDependents(false)
                getDependents()
                setLoader(false)
            }
        } catch (error) {
            setLoader(false)
            popupNotification('Something went wrong', false)
        }
    }

    useEffect(() => {
        getAllDependents()
    }, [])
    return (
        <div className="container-right">
            <div className="main-title">
                <div style={{ position: 'relative' }}>
                    <Stack
                        direction="row"
                        sx={{
                            position: 'absolute',
                            left: '240px',
                            top: '-85px',
                            '@media (max-width: 786px)': {
                                left: '10px',
                                top: '-45px',
                            },
                        }}
                    >
                        <Button
                            style={{background:'#176571'}}
                            variant="contained"
                            size="small"
                            startIcon={<LibraryAddOutlinedIcon />}
                            onClick={() => {
                                setSelectedDependent(null)
                                setShowAddDialog(true)
                            }}
                        >
                            Add Dependent
                        </Button>
                    </Stack>
                </div>
            </div>
            <div className="white-container dependents">
                {loader ? (
                    <Loader pageName="dependents" />
                ) : (
                    <>
                        <DataGrid
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            disableSelectionOnClick
                            rows={dependents}
                            getRowId={(r) => r.providerType}
                            pageSize={10}
                        />
                        <Dialog
                            open={open}
                            onClose={handleCloseModal}
                            aria-labelledby="alert-dialog-title"
                            aria-describedby="alert-dialog-description"
                        >
                            <DialogTitle id="alert-dialog-title">Delete Dependent</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="alert-dialog-description">
                                    Are you sure you want to delete dependent?
                                </DialogContentText>
                            </DialogContent>

                            <DialogActions>
                                {/* Cancel deletion */}
                                <Button onClick={handleCloseModal} className="text-uppercase">
                                    Cancel
                                </Button>

                                {/* Confirm deletion */}
                                <Button
                                    color="primary"
                                    variant="contained"
                                    className="common-button"
                                    onClick={() => handleDelete(dependentId)}
                                >
                                    Delete
                                </Button>
                            </DialogActions>
                        </Dialog>

                        {showAddDialog && (
                            <AddDependentModal
                                title={selectedDependent ? 'Edit Dependent' : 'Add New Dependent'}
                                isOpen={showAddDialog}
                                onClose={() => setShowAddDialog(false)}
                                onAdd={(item) => handleAdd(item)}
                                selectedDependent={selectedDependent}
                            />
                        )}
                    </>
                )}
            </div>
        </div>
    )
}
export default Dependants
