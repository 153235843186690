import React, { useState } from 'react'
import { Link, useRouteMatch } from 'react-router-dom'
import ReactLoading from 'react-loading'

import Button from '@mui/material/Button'
// import TextField from '@mui/material/TextField'
import { VisibilityOutlined, VisibilityOffOutlined } from '@mui/icons-material'
import logo from 'assets/logos/MatchRiteCare_logo_color_gradient_102220.jpg'
import { Box, Grid, IconButton, InputAdornment, MenuItem, Typography } from '@mui/material'
import { useStyles } from './Login.styles'
import MrcButton from 'components/MrcButton'
import { styled } from '@mui/material/styles'
import EmailIcon from '@mui/icons-material/Email'
import TextFieldsIcon from '@mui/icons-material/TextFields'
import PhoneIcon from '@mui/icons-material/Phone'
import LockIcon from '@mui/icons-material/Lock'
import useMediaQuery from '@mui/material/useMediaQuery'
import { useTheme } from '@mui/material/styles'
import useLogin from './useLogin'
import FormHelperText from '@mui/material/FormHelperText'
import { genderData, languageData, relationData } from 'use/Const'
// import MrcTextField from 'components/Form/TextField/TextField'
import TextField from 'components/Form/TextField'
import logoIcon from 'assets/logos/MatchRiteCare_login-logo.png'
// import logoIcon from 'assets/icons/icon2.png'
import PrivacyPolicy from 'components/NewPrivacyPolicy'
import TermsModal from './TermsModal'
import TermsAndCondition from 'components/NewTermsAndCondition'

const PrimaryActionButton = styled(MrcButton)(({ theme }) => ({
    minHeight: '45px',
    width: '100%',
    borderTopLeftRadius: 0,
    borderBottomLeftRadius: 0,
    // backgroundColor: '#176571',
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
    // ':hover': {
    //     backgroundColor: '#176571',
    // },
    '&.revertColors': {
        backgroundColor: theme.palette.common.white,
        // color: '#176571',
        color: theme.palette.primary.main,
        width: 'auto',
        minWidth: '50%',
        padding: '0 20px',
    },
}))

const LoginPage = (props) => {
    const isLoginPage = useRouteMatch('/login')
    const isSignUpPage = useRouteMatch('/signup')
    const isForgotPasswordPage = useRouteMatch('/forgotpassword')
    const {
        signIn,
        signup,
        handleConfirmationSubmit,
        handleSendCodeClick,
        handleConfirmClick,
        handleResendCode,
        state,
        setState,
    } = useLogin()

    const [openTermsAndCondition, setOpenTermsAndCondition] = useState(false)
    const [openPrivacyPolicy, setOpenPrivacyPolicy] = useState(false)
    const classes = useStyles()
    const theme = useTheme()
    const isLargeScreen = useMediaQuery(theme.breakpoints.up('lg'))
    const saveEmail = React.useCallback(
        (emailToValidate) => {
            const validationRegex =
                /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/

            setState({ email: emailToValidate, emailValid: emailToValidate.match(validationRegex) })
        },
        [setState]
    )

    const savePassword = React.useCallback(
        (passwordToValidate) => {
            setState({ password: passwordToValidate, passwordValid: passwordToValidate.length > 8 })
        },
        [setState]
    )

    const saveFirstName = React.useCallback(
        (firstName) => {
            setState({ firstName: firstName, firstNameValid: firstName.length > 3 })
        },
        [setState]
    )
    const saveLastName = React.useCallback(
        (lastName) => {
            setState({ lastName, lastNameValid: lastName.length > 3 })
        },
        [setState]
    )
    const saveGender = React.useCallback(
        (gender) => {
            setState({ gender, genderValid: gender })
        },
        [setState]
    )
    const savePhone = React.useCallback(
        (phone) => {
            setState({ phone, phoneValid: phone.length > 9 })
        },
        [setState]
    )

    const savePreferredLanguage = React.useCallback(
        (preferredLanguage) => {
            setState({ preferredLanguage, preferredLanguageValid: preferredLanguage })
        },
        [setState]
    )

    const saveRelationship = React.useCallback(
        (relationShip) => {
            setState({ relationShip, relationShipValid: relationShip })
        },
        [setState]
    )

    const { textFields, handleFormSubmit } = React.useMemo(() => {
        const emailField = {
            type: 'email',
            label: 'Email',
            value: state.email,
            onChange: (event) => saveEmail(event.target.value),
            error: !state.emailValid,
            helperText: !state.emailValid ? 'Please enter a valid email' : '',
            Icon: EmailIcon,
        }

        const firstNameField = {
            type: 'text',
            label: 'First Name',
            value: state.firstName,
            onChange: (event) => saveFirstName(event.target.value),
            // required: true,
            error: !state.firstNameValid,
            helperText: !state.firstNameValid ? 'Please enter a valid Name' : '',
            Icon: TextFieldsIcon,
        }

        const lastNameField = {
            type: 'text',
            label: 'Last Name',
            value: state.lastName,
            onChange: (event) => saveLastName(event.target.value),
            // required: true,
            error: !state.lastNameValid,
            helperText: !state.lastNameValid ? 'Please enter a valid Name' : '',
            Icon: TextFieldsIcon,
        }

        const genderField = {
            label: 'Gender',
            value: state.gender,
            onChange: (event) => saveGender(event.target.value),
            error: !state.genderValid,
            helperText: !state.genderValid ? 'Please enter a valid gender' : '',
            Icon: <></>,
            childrenItems: genderData.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            )),
        }
        const relationShipField = {
            label: 'Relationship',
            value: state.relationShip,
            onChange: (event) => saveRelationship(event.target.value),
            error: !state.relationShipValid,
            Icon: <></>,
            childrenItems: relationData.map((option) => (
                <MenuItem key={option.value} value={option.value}>
                    {option.label}
                </MenuItem>
            )),
        }

        const preferredLanguageField = {
            label: 'Preferred Language',
            value: state.preferredLanguage,
            onChange: (event) => savePreferredLanguage(event.target.value),
            // required: true,
            error: !state.preferredLanguageValid,
            helperText: !state.preferredLanguageValid ? 'Please enter a valid preferred Language' : '',
            Icon: <></>,
            childrenItems: languageData.map((option) => (
                <MenuItem key={option} value={option}>
                    {option}
                </MenuItem>
            )),
        }

        const phoneField = {
            type: 'text',
            label: 'Phone Number',
            value: state.phone,
            onChange: (event) => savePhone(event.target.value),
            // required: true,
            error: !state.phoneValid,
            helperText: !state.phoneValid ? 'Please enter a valid phone (more than 8 digits)' : '',
            Icon: PhoneIcon,
        }

        const passwordField = {
            type: state['showPassword'] ? 'text' : 'password',
            label: 'Password',
            name: 'showPassword',
            value: state.password,
            onChange: (event) => savePassword(event.target.value),
            error: !state.passwordValid,
            helperText: !state.passwordValid ? 'Password must be at least 8 characters' : '',
            Icon: LockIcon,
        }

        const confirmPasswordField = {
            type: state['showConfirmPassword'] ? 'text' : 'password',
            name: 'showConfirmPassword',
            label: 'Confirm Password',
            value: state.confirmPassword,
            onChange: (event) => setState({ confirmPassword: event.target.value }),
            error: state.password && state.password !== state.confirmPassword,
            helperText: state.password && state.password !== state.confirmPassword ? 'Passwords do not match' : '',
            Icon: LockIcon,
        }

        const confirmCodeField = {
            type: 'confirmationCode',
            label: 'Confirmation Code',
            value: state.confirmationCode,
            onChange: (event) => setState({ confirmationCode: event.target.value }),
            Icon: LockIcon,
        }

        const defaultFields = [emailField, passwordField]
        if (isLoginPage && !state.userEmailVerified) {
            return {
                textFields: [confirmCodeField],
                handleFormSubmit: handleConfirmationSubmit,
            }
        }
        if (isSignUpPage) {
            if (state.newUser !== null) {
                return {
                    textFields: [confirmCodeField],
                    handleFormSubmit: handleConfirmationSubmit,
                }
            }
            return {
                textFields: [
                    firstNameField,
                    lastNameField,
                    phoneField,
                    genderField,
                    preferredLanguageField,
                    relationShipField,
                    ...defaultFields,
                    confirmPasswordField,
                ],
                handleFormSubmit: signup,
            }
        }

        if (isForgotPasswordPage) {
            if (!state.isCodeSent) {
                return {
                    textFields: [emailField],
                    handleFormSubmit: handleSendCodeClick,
                }
            } else if (!state.isConfirmed) {
                return {
                    textFields: [
                        {
                            ...confirmCodeField,
                            secondaryText: `Please check your email ${state.email} for the confirmation code.`,
                        },
                        passwordField,
                        confirmPasswordField,
                    ],
                    handleFormSubmit: handleConfirmClick,
                }
            }
        }

        return {
            textFields: defaultFields,
            handleFormSubmit: signIn,
        }
    }, [
        handleConfirmClick,
        handleConfirmationSubmit,
        handleSendCodeClick,
        isForgotPasswordPage,
        isSignUpPage,
        saveEmail,
        saveFirstName,
        saveGender,
        saveLastName,
        savePassword,
        savePhone,
        setState,
        signIn,
        signup,
        state.confirmPassword,
        state.confirmationCode,
        state.email,
        state.emailValid,
        state.firstName,
        state.gender,
        state.genderValid,
        state.isCodeSent,
        state.isConfirmed,
        state.lastName,
        state.nameValid,
        state.newUser,
        state.password,
        state.passwordValid,
        state.phone,
        state.phoneValid,
    ])

    const handlePrivacyPolicy = (checked) => {
        if (checked) {
            setState({ policyAgree: false })

            setOpenPrivacyPolicy(true)
        } else {
            setState({ policyAgree: checked })
        }
    }

    const handleTermsAndCondition = (checked) => {
        if (checked) {
            setState({ termsAgree: false })
            setOpenTermsAndCondition(true)
        } else {
            setState({ termsAgree: checked })
        }
    }

    return (
        <div className={classes.mainContainer}>
            <Grid
                container
                className={classes.mainContainer}
                style={
                    isLargeScreen
                        ? { minHeight: '100vh', maxWidth: '1225px', margin: 'auto' }
                        : isSignUpPage
                        ? { minHeight: 'auto' }
                        : { minHeight: '100vh' }
                }
            >
                {/* <div style={{ backgroundColor: '#2a7cce', height: isLoginPage ? '100vh' : 'auto' }}> */}
                {/* <Grid container style={isLargeScreen ? { minHeight: '100vh', maxWidth: '1225px', margin: 'auto' } : {}}> */}
                <Grid item xs={12}>
                    <Grid container className={classes.appBarContainer}>
                        <Grid item xs={12} md={6} className={classes.appBarTitle}>
                            SIGN IN TO MATCHRITE CARE
                        </Grid>
                        <Grid item xs={12} md={6} className={classes.appBarSubTitle}>
                            <div>
                                {isLoginPage && (
                                    <>
                                        Not a member?{' '}
                                        <Link to="/signup" className="py-3">
                                            <b className="text-white">Sign Up</b>
                                        </Link>
                                    </>
                                )}

                                {!isLoginPage && (
                                    <>
                                        Already a member?{' '}
                                        <Link to="/login" className="py-3">
                                            <b className="text-white">Sign In</b>
                                        </Link>
                                    </>
                                )}
                            </div>
                        </Grid>
                    </Grid>
                </Grid>
                {isLargeScreen && (
                    <Grid item xs={12} lg={6} className={classes.loginLeftSection}>
                        <Box className="content">
                            {/* greetings */}
                            <img
                                src={logoIcon}
                                alt="logo"
                                style={{
                                    // marginLeft: 'auto',
                                    // marginRight: 'auto',
                                    display: 'block',
                                    resizeMode: 'cover',
                                }}
                            />
                            <Typography variant="h1" className="py-5 text-white">
                                Welcome
                            </Typography>

                            {isLoginPage && (
                                <div className="mb-4">
                                    <Typography variant="h5" className="py-0 text-white">
                                        Don't have an account?
                                    </Typography>
                                    <Typography variant="h5" className="py-0 text-white">
                                        Create your account, it takes less than a minute.
                                    </Typography>

                                    {/* Button that redirects to signup page */}
                                    <Link to="/signup" className="py-3">
                                        <PrimaryActionButton className="revertColors mt-3">SIGN UP</PrimaryActionButton>
                                    </Link>
                                </div>
                            )}

                            {!isLoginPage && (
                                <>
                                    <Typography variant="h5" className="py-0 text-white">
                                        Already a member? Sign in to Continue.
                                    </Typography>

                                    {/* Button that redirects to signup page */}
                                    <Link to="/login" className="py-3">
                                        <PrimaryActionButton className="revertColors mt-3">SIGN IN</PrimaryActionButton>
                                    </Link>
                                </>
                            )}
                        </Box>
                    </Grid>
                )}
                <Grid item xs={12} lg={6} className={classes.loginRightSection}>
                    <div className="content">
                        <div className="bg-white p-5 shadow rounded-3 my-4">
                            {/* Display matchrite care logo */}
                            <div className={classes.logo}>
                                <img alt="logo" src={logo} />
                            </div>

                            {/* Only show div with username and password textfields when the popup div is hidden, or else textfields will show through popup div*/}

                            <div className="mt-3">
                                <div>
                                    {textFields.map(({ Icon, secondaryText, InputProps, ...textFields }) => (
                                        <>
                                            <TextField
                                                error={textFields.error}
                                                select={!!textFields.childrenItems}
                                                required
                                                fullWidth
                                                margin="normal"
                                                variant="outlined"
                                                InputProps={{
                                                    disableUnderline: true,
                                                    endAdornment: (
                                                        <InputAdornment
                                                            sx={{
                                                                display: !textFields.label.includes('Password')
                                                                    ? 'none'
                                                                    : null,
                                                            }}
                                                            position="end"
                                                        >
                                                            <IconButton
                                                                aria-label="toggle password visibility"
                                                                //   onMouseDown={handleMouseDownPassword}
                                                                onClick={() =>
                                                                    setState({
                                                                        [textFields.name]: !state[textFields.name],
                                                                    })
                                                                }
                                                            >
                                                                {state[textFields.name] ? (
                                                                    <VisibilityOutlined
                                                                        sx={{
                                                                            color: '#3D3E5C',
                                                                        }}
                                                                    />
                                                                ) : (
                                                                    <VisibilityOffOutlined
                                                                        sx={{
                                                                            color: '#3D3E5C',
                                                                        }}
                                                                    />
                                                                )}
                                                            </IconButton>
                                                        </InputAdornment>
                                                    ),
                                                }}
                                                {...textFields}
                                            >
                                                {textFields.childrenItems}
                                            </TextField>
                                            {secondaryText && (
                                                <>
                                                    <h6 className="py-3">
                                                        <FormHelperText>{secondaryText}</FormHelperText>
                                                    </h6>

                                                    <hr />
                                                </>
                                            )}
                                        </>
                                    ))}
                                </div>
                            </div>

                            {/* Button for user to sign in */}
                            <div className="py-3">
                                <PrimaryActionButton
                                    disabled={
                                        state.loading || (isSignUpPage && (!state.termsAgree || !state.policyAgree))
                                    }
                                    onClick={handleFormSubmit}
                                >
                                    {state.loading && (
                                        <ReactLoading width={22} height={22} type="spokes" color="#FFFFFF" />
                                    )}
                                    {!state.loading && (
                                        <>
                                            {isLoginPage && state.userEmailVerified && 'SIGN IN'}
                                            {isLoginPage && !state.userEmailVerified && 'Verify'}
                                            {isSignUpPage && state.newUser === null && 'SIGN UP'}
                                            {isSignUpPage && state.newUser !== null && 'Verify'}
                                            {isForgotPasswordPage && !state.isCodeSent && 'Send Confirmation'}
                                            {isForgotPasswordPage && state.isCodeSent && 'Confirm'}
                                        </>
                                    )}
                                </PrimaryActionButton>
                            </div>
                            {isSignUpPage && state.newUser === null && (
                                <>
                                    <div>
                                        <input
                                            type="checkbox"
                                            id="privacy_policy_agree"
                                            onChange={(e) => handlePrivacyPolicy(e.currentTarget.checked)}
                                            // onChange={(e) => setState({ agree: e.currentTarget.checked })}
                                            checked={state.policyAgree}
                                        />
                                        <label
                                            htmlFor="privacy_policy_agree"
                                            style={{ paddingLeft: '5px' }}
                                            onChange={(e) => handlePrivacyPolicy(e.currentTarget.checked)}
                                        >
                                            I accept Privacy Policy
                                        </label>
                                    </div>
                                    <div>
                                        <input
                                            type="checkbox"
                                            id="terms_condition_agree"
                                            onChange={(e) => handleTermsAndCondition(e.currentTarget.checked)}
                                            // onChange={(e) => setState({ agree: e.currentTarget.checked })}
                                            checked={state.termsAgree}
                                        />
                                        <label
                                            htmlFor="terms_condition_agree"
                                            style={{ paddingLeft: '5px' }}
                                            onChange={(e) => handleTermsAndCondition(e.currentTarget.checked)}
                                        >
                                            I accept Terms and Conditions
                                        </label>
                                    </div>
                                </>
                            )}

                            {isLoginPage && !state.userEmailVerified && (
                                <h6 className="py-0 text-center">
                                    <Button disabled={state.isCodeSent} onClick={handleResendCode}>
                                        {state.resendCodeLoading && (
                                            <ReactLoading
                                                width={22}
                                                height={22}
                                                type="spokes"
                                                color="rgb(129 121 121)"
                                            />
                                        )}
                                        {!state.resendCodeLoading && 'Resent Verification Code'}
                                    </Button>
                                </h6>
                            )}
                            {isLoginPage && state.userEmailVerified && (
                                <h6 className="py-0 text-center">
                                    <Link to="/forgotpassword" style={{ color: '#176571' }}>
                                        Forgot password?
                                    </Link>
                                </h6>
                            )}
                        </div>
                        {!isLargeScreen && (
                            <Box className="subContent py-4" sx={{ textAlign: 'center', fontSize: '14px' }}>
                                {isLoginPage && (
                                    <>
                                        <Typography variant="h5" className="py-0 text-white">
                                            Don't have an account?
                                        </Typography>
                                        <Typography variant="h5" className="py-0 text-white">
                                            Create your account, it takes less than a minute.
                                        </Typography>

                                        {/* Button that redirects to signup page */}
                                        <Link to="/signup" className="py-3">
                                            <PrimaryActionButton className="revertColors mt-3">
                                                SIGN UP
                                            </PrimaryActionButton>
                                        </Link>
                                    </>
                                )}

                                {!isLoginPage && (
                                    <>
                                        <Typography variant="h5" className="py-0 text-white">
                                            Already a member? Sign in to Continue.
                                        </Typography>

                                        {/* Button that redirects to signup page */}
                                        <Link to="/login" className="py-3">
                                            <PrimaryActionButton className="revertColors mt-3">
                                                SIGN IN
                                            </PrimaryActionButton>
                                        </Link>
                                    </>
                                )}
                            </Box>
                        )}
                        {/* Buttons to display privacy policy and terms and conditions */}
                        <div className={classes.termsContainerButton}>
                            <Button
                                className="text-white"
                                onClick={() => window.open('https://www.matchritecare.com/privacy-policy', '_blank')}
                            >
                                Privacy Policy
                            </Button>
                            <Button
                                className="text-white"
                                onClick={() =>
                                    window.open('https://www.matchritecare.com/terms-and-conditions', '_blank')
                                }
                            >
                                Terms and Conditions
                            </Button>
                        </div>
                    </div>
                </Grid>
            </Grid>
            {openPrivacyPolicy && (
                <TermsModal
                    fullWidth
                    title="Privacy Policy "
                    open={openPrivacyPolicy}
                    handleConfirm={() => {
                        setState({ policyAgree: true })
                        setOpenPrivacyPolicy(false)
                    }}
                    setSubmit={() => {
                        setOpenPrivacyPolicy(false)
                    }}
                >
                    <PrivacyPolicy />
                </TermsModal>
            )}
            {openTermsAndCondition && (
                <TermsModal
                    fullWidth
                    title="Terms and Condition "
                    open={openTermsAndCondition}
                    handleConfirm={() => {
                        setState({ termsAgree: true })
                        setOpenTermsAndCondition(false)
                    }}
                    setSubmit={() => {
                        setOpenTermsAndCondition(false)
                    }}
                >
                    <TermsAndCondition />
                </TermsModal>
            )}
        </div>
    )
}

export default LoginPage
