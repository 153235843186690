import React from 'react'
import DataGrid from 'components/DataTable/DataGridWrapper'

import { getDate } from 'use/Helper'
import Loader from 'components/Loader/Loader'
import AlertDialog from 'components/AlertDialog'
import { DiagnosticReportByProfileIdAPI } from 'services/Services'
import { useSearchField } from 'hoc/SearchFieldProvider'
import filterEntries from 'util/filterEntries'
import { IconButton } from '@mui/material'
import { ToolTipIcon } from 'assets/svg/tooltipIcon'
import AssignmentIcon from '@mui/icons-material/Assignment'
import { RecordsService } from 'services/myrecords.service'
class LabsImpl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            cerner: '',
            open: false,
            selected: {},
            loading: true,
            openDoc: false,
            docHTML: '',
        }
    }

    componentDidMount() {
        const { setSearchColumns, selectedDependent } = this.props
        this.getDiagnosticReportByProfileId(selectedDependent?.id)
        setSearchColumns([
            {
                value: 'providerName',
                label: 'Provider name',
            },
            {
                value: 'category',
                label: 'Category',
            },
            {
                value: 'patient',
                label: 'Patient',
            },
            {
                value: 'performer',
                label: 'Performer',
            },
            {
                value: 'status',
                label: 'Status',
            },
            {
                value: 'effectiveDateTime',
                label: 'Date',
            },
        ])
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedDependent } = this.props
        if (selectedDependent?.id !== prevProps.selectedDependent?.id) {
            this.getDiagnosticReportByProfileId(selectedDependent?.id)
        }
    }
    // Function to retrieve patient labs from Matchrite API
    getDiagnosticReportByProfileId = async (selectedDependentId) => {
        let profileid = selectedDependentId || localStorage.getItem('profileid')
        this.setState({
            loading: true,
        })
        let cerner = localStorage.getItem('cerner')
        const data = await DiagnosticReportByProfileIdAPI(profileid)

        // Get test user records
        // const data = await DiagnosticReportByProfileIdAPI(135)

        if (cerner === -1) {
            return <div>EHR</div>
        } else {
            if (data) {
                if (data.message === 'No Data Found') {
                    await this.setState({ value: [], loading: false })
                } else {
                    await this.setState({
                        value: data.diagnosticReport,
                        loading: false,
                    })
                }
            } else {
                this.setState({ loading: false })
            }
        }
    }

    getFilteredEntries = () => {
        const { value } = this.state
        const { searchTerm, filterByColumn } = this.props
        const allColumns = ['providerName', 'category', 'patient', 'issued', 'effectiveDateTime', 'performer', 'status']
        const columns = filterByColumn === 'all' ? allColumns : [filterByColumn]
        return filterEntries(value, columns, searchTerm)
    }

    handleOpenDocument = async (docId) => {
        const { data: result } = await RecordsService.getDiagnosticReport(docId)
        this.setState({
            docHTML: result && result.length ? result[0]?.fhirData || '' : '<div>No Document Exist!</div>',
            openDoc: true,
        })
    }

    render() {
        if (this.state.loading) {
            return <Loader pageName="labs" />
        }

        // Columns for data grid that will that will display patient's documented labs
        const columns = [
            {
                field: 'providerName',
                headerName: 'Provider name',
                flex: 0.5,
            },
            {
                field: 'patient',
                headerName: 'Patient',
                flex: 0.5,
            },
            {
                field: 'effectiveDateTime',
                headerName: 'Effective Date',
                type: 'date',
                flex: 0.5,
                valueFormatter: (params) => getDate(params.value),
                renderCell: (param) => (param.value ? getDate(param.value) : ''),
            },
            {
                field: 'category',
                headerName: 'Category',
                flex: 0.5,
                // renderCell: (params) => params.row.category?.map((category) => category.text).join(','),
            },
            {
                field: 'code',
                headerName: 'Code',
                flex: 0.5,
                // renderCell: (param) => (param.value ? getDate(param.value) : ''),
            },
            // {
            //     field: 'issued',
            //     headerName: 'Date',
            //     type: 'date',
            //     flex: 0.5,
            //     valueFormatter: (params) => getDate(params.value),
            //     renderCell: (param) => (param.value ? getDate(param.value) : ''),
            // },
            // {
            //     field: 'performer',
            //     headerName: 'Performer',
            //     flex: 0.5,
            // },
            // {
            //     field: 'status',
            //     headerName: 'Status',
            //     flex: 0.5,
            // },
            {
                field: '',
                headerName: 'Click icon for details',
                flex: 0.2,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (param) => (
                    // Icon clicked to display consolidated information about data row
                    <>
                        <IconButton
                            onClick={() =>
                                this.setState({
                                    selected: param.row,
                                    open: true,
                                })
                            }
                        >
                            <ToolTipIcon />
                        </IconButton>
                        <IconButton onClick={() => this.handleOpenDocument(param.row?.id)}>
                            <AssignmentIcon className="light-blue" />
                        </IconButton>
                    </>
                ),
            },
        ]

        return (
            <div className="container-right">
                {/* Data grid that populates cells with patient lab data (limit 5 rows per page)*/}
                <div className="white-container">
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            rows={this.getFilteredEntries()}
                            disableSelectionOnClick
                            getRowId={(r) => JSON.stringify(r)}
                        />
                    </div>
                    {/* Popup that displays information about a lab row when the icon in it's furthest right column is clicked */}
                    {Object.keys(this.state.selected).length > 0 && (
                        <AlertDialog
                            title="Lab details"
                            fullWidth
                            open={this.state.open}
                            setOpen={(value) => this.setState({ open: value })}
                        >
                            {/* <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <label className="fw-bold">Provider name:</label> {this.state.selected.providerName}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Patient Name:</label> {this.state.selected.patient}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Performer:</label> {this.state.selected.performer}
                            </div> */}
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Code:</label> {this.state.selected.code}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Status:</label> {this.state.selected.status}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Value:</label> {this.state.selected.value}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Interpretation :</label> {this.state.selected.interpretation}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">View :</label>
                            </div>
                            {/* <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Effective date:</label>{' '}
                                {this.state.selected.effectiveDateTime
                                    ? getDate(this.state.selected.effectiveDateTime)
                                    : ''}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Issued:</label>{' '}
                                {this.state.selected.issued ? getDate(this.state.selected.issued) : ''}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Status:</label> {this.state.selected.status}
                            </div> */}
                        </AlertDialog>
                    )}
                    {this.state.openDoc && (
                        <AlertDialog
                            title="Lab Document Detail"
                            fullWidth
                            open={this.state.openDoc}
                            setOpen={(value) => this.setState({ openDoc: value, docHTML: '' })}
                        >
                            <span dangerouslySetInnerHTML={{ __html: this.state.docHTML }} />
                        </AlertDialog>
                    )}
                </div>
            </div>
        )
    }
}

export function Labs(props) {
    const { searchTerm, filterByColumn, setSearchColumns, selectedDependent } = useSearchField()

    return (
        <LabsImpl
            {...props}
            searchTerm={searchTerm}
            filterByColumn={filterByColumn}
            setSearchColumns={setSearchColumns}
            selectedDependent={selectedDependent}
        ></LabsImpl>
    )
}

export default Labs
