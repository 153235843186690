import React, { useCallback, useEffect } from 'react'
import { BrowserRouter as Router, Route, Redirect, Switch } from 'react-router-dom'

import Header from 'components/layout/Header/Header'
import SideBar from 'components/layout/SideBar/SideBar'

import Sam from './Sam/Sam'
import Amber from './Amber/Amber'
import Jason from './Jason/Jason'
import Wilson from './Wilson/Wilson'
import Idcard from './IdCard/IdCard'
import Claims from './Claims/Claims'
import ViewXray from './ViewXray/ViewXray'
import YourClaims from './Claims/YourClaims'
import MyProfile from './MyProfile/MyProfile'
import ShareMyRecords from './MyDoctors/ShareMyRecords'
import ImportRecords from './ImportRecords/ImportRecords'
import Dependents from './Dependants/Dependants'
import Encounters from './Encounters/Encounters'
import AddYourDoctor from './MyDoctors/AddYourDoctor'
import Appointments from './Appointments/Appointments'
import FindProvider from './FindProvider/FindProvider'
// import ShareRecords from './ShareRecords/ShareRecords'
import covidpassport from './CovidPassport/CovidPassport'
import EncounterDetail from './Encounters/EncounterDetail'
import CreateDependent from './Dependants/CreateDependant'
import VeteranHistory from './VeteranHistory/VeteranHistory'
import Disability from './Disability/Disability'

import MyRecords from './MyRecords/MyRecords'
import BookAppointmentRequest from './BookAppointment/BookAppointment'
import { Grid } from '@mui/material'
import { useTheme } from '@mui/material/styles'
import useMediaQuery from '@mui/material/useMediaQuery'
import logo from 'assets/logos/MatchRiteCare_logo_3Color_White.png'
import PageHeader from 'components/PageHeader/PageHeader'
import FollowUs from 'components/FollowUs'
import { useStyles } from './Home/Home.styles'
import AddOrganization from './MyDoctors/AddOrganization'
import UserPrivacyPolicy from './UserPrivacyTerms/UserPrivacyPolicy'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'
import { allowAnonymous } from 'App'
import { isValidToken, setSession } from 'util/auth.util'
import { useState } from 'react'
const MENU_WIDTH = '255px'


// Home as functional component
const Home = (props) => {
    const history = useHistory()
    const theme = useTheme()
    const isMediumScreen = useMediaQuery(theme.breakpoints.up('md'))
    const classes = useStyles()
    const isAuthenticated = () => {
        if (localStorage.getItem('token')) {
            return isValidToken(localStorage.getItem('token'))
        }
        return false
    }
    const [authenticated] = useState(isAuthenticated())
    useEffect(() => {
        const { pathname } = window.location
        const isNotAllowedAnonymous = !allowAnonymous.some((path) => path.includes(pathname))
        if (!authenticated && isNotAllowedAnonymous) {
            history.push('/login')
        }
    }, [history, authenticated])
    const initialize = useCallback(async () => {
        try {
            const token = localStorage.getItem('token')
            const refreshToken = localStorage.getItem('refreshToken')
            if (token && isValidToken(token)) {
                setSession(token, refreshToken)
            }
        } catch (error) {
            history.push('/login')
        }
    }, [])
    useEffect(() => {
        initialize()
    }, [initialize])

    return (
        <Router>
            <Grid container>
                {isMediumScreen && (
                    <Grid item bgcolor="#176571" style={{ width: MENU_WIDTH, minHeight: '100vh' }}>
                    {/* <Grid item bgcolor="#2a7cce" style={{ width: MENU_WIDTH, minHeight: '100vh' }}> */}
                        <Grid
                            className="custom-scroll"
                            container
                            direction="column"
                            justifyContent="start"
                            alignItems="center"
                            minHeight="100%"
                            bgcolor="#176571"
                            // bgcolor="#2a7cce"
                            style={{
                                width: MENU_WIDTH,
                                position: 'fixed',
                                left: 0,
                                flexWrap: 'nowrap',
                                overflowX: 'auto',
                            }}
                        >
                            <img alt="MatchRite logo" className={classes.logo} src={logo} />

                            <Grid
                                container
                                flex={1}
                                direction="column"
                                justifyContent="space-between"
                                alignItems="center"
                            >
                                <SideBar />
                                <FollowUs />
                                {/* <Footer /> */}
                            </Grid>
                        </Grid>
                    </Grid>
                )}
                <Grid
                    item
                    xs
                    className={classes.bodyContainer}
                    style={{ maxWidth: isMediumScreen ? `calc(100% - ${MENU_WIDTH})` : '100%' }}
                >
                    <Header />
                    <PageHeader />
                    <Switch>
                        <Redirect exact from="/home" to="/home/myprofile" />
                        <Route exact path="/home/myrecords/:tabName?" component={MyRecords} />
                        <Route exact path="/home/sam" component={Sam} />
                        <Route exact path="/home/amber" component={Amber} />
                        <Route exact path="/home/wilson" component={Wilson} />
                        <Route exact path="/home/claims" component={Claims} />
                        <Route exact path="/home/idcard" component={Idcard} />
                        <Route exact path="/home/jason/:pid" component={Jason} />
                        <Route exact path="/home/viewxray" component={ViewXray} />
                        <Route exact path="/home/myprofile" component={MyProfile} />
                        <Route exact path="/home/importrecords" component={ImportRecords} />
                        <Route exact path="/home/share-records" component={ShareMyRecords} />
                        <Route exact path="/home/yourclaims" component={YourClaims} />
                        <Route exact path="/home/dependents" component={Dependents} />
                        <Route exact path="/home/encounters" component={Encounters} />
                        {/* <Route exact path="/home/share-records" component={ShareRecords} /> */}
                        <Route exact path="/home/appointments" component={Appointments} />
                        <Route exact path="/home/findprovider" component={FindProvider} />
                        <Route exact path="/home/addyourdoctor" component={AddYourDoctor} />
                        <Route exact path="/home/addOrganization" component={AddOrganization} />
                        <Route exact path="/home/covidpassport" component={covidpassport} />
                        <Route exact path="/home/createdependent" component={CreateDependent} />
                        <Route exact path="/home/encounterdetail" component={EncounterDetail} />
                        <Route exact path="/home/veteranhistory" component={VeteranHistory} />
                        <Route exact path="/home/disability" component={Disability} />
                        <Route exact path="/home/privacypolicy" component={UserPrivacyPolicy} />
                        <Route exact path="/home/termsandconditions" component={UserPrivacyPolicy} />

                        <Route
                            exact
                            path="/home/book/:pname/:pid/:pexperience/:ptype/:paddress"
                            component={BookAppointmentRequest}
                        />
                        <Redirect to="/home/myprofile" />
                    </Switch>
                </Grid>
            </Grid>
        </Router>
    )
}

export default Home
