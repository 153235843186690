import React, { useState } from 'react'
import Modal from '@mui/material/Modal'
import config from 'use/Config'
import MrcButton from 'components/MrcButton'
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined'
const getFilePath = (docid) => {
    return (
        config.ccda.helperUrl + process.env.REACT_APP_MRC_BASE_URL + '/MergeEHR/DocumentRefrenceBinary?docid=' + docid
    )
}
const CcdaHelper = ({ docid, buttonProps }) => {
    const [count, setCount] = useState(false)
    const handleClick = () => setCount((count) => true)
    const handleClose = () => setCount((count) => false)
    const filePath = getFilePath(docid)

    return (
        <div>
            <MrcButton startIcon={<VisibilityOutlinedIcon />} onClick={handleClick} {...buttonProps}>
                View document
            </MrcButton>

            <Modal
                open={count}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"
            >
                <div className="modal-size">
                    <iframe className="iframe-size" src={filePath} title="ccda" />
                </div>
            </Modal>
        </div>
    )
}

export default CcdaHelper
