import { useState } from 'react'

import FormGroupHeader from 'components/FormGroupHeader/FormGroupHeader'
import MrcTooltip from 'components/MrcTooltip'
import { IconButton, Stack, Typography } from '@mui/material'
import { ImportFolderIcon } from 'assets/svg/importFolderIcon'

import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import Button from '@mui/material/Button'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DataGrid from 'components/DataTable/DataGridWrapper'
import DeleteOutlinedIcon from '@mui/icons-material/DeleteOutlined'
import CachedIcon from '@mui/icons-material/Cached'
import popupNotification from 'components/Notification/popupNotification'
import ReactLoading from 'react-loading'

import { NewToolTipIcon } from '../../../components/NewToolTipIcon'
import { DeleteEHRRecordAPI, GetEhrEndpoint } from 'services/Services'

export const AssociatedProviderList = ({ state, setState, facilityChange, getConnectedEHRRecords }) => {
    const [loadingEhr, setLoadingEhr] = useState('')
    const refetchEHR = async (selectedRow) => {
        try {
            setLoadingEhr(selectedRow.ehrEndPointId)
            const selectedFacility = await GetEhrEndpoint(selectedRow.ehrEndPointId)
            localStorage.setItem('selectedProfileId', selectedRow.profileId)
            const link = facilityChange(selectedFacility, true)
            setTimeout(() => {
                setLoadingEhr('')
                window.location.replace(link)
            }, [1500])
        } catch (error) {
            setLoadingEhr('')
        }
    }

    // Removes patient records from a particular provider
    const deleteYourRecord = async () => {
        let requestOptions = {
            method: 'DELETE',
            redirect: 'follow',
        }

        const response = await DeleteEHRRecordAPI(state.selectedProfileId, state.delFacility, requestOptions)
        if (response.message === 'Deleted successfully!') {
            popupNotification(response.message, true)
            handleClose()
            getConnectedEHRRecords()
        } else {
            popupNotification(response.message, false)
            handleClose()
        }
    }

    const handleClose = () => {
        setState((prevState) => ({
            ...prevState,
            open: false,
        }))
    }

    const columns = [
        {
            field: 'providerType',
            headerName: 'Providers',
            flex: 1,
            renderCell: (params) => {
                const color = params.row?.providerType?.toLowerCase().includes('cerner')
                    ? '#FED45E'
                    : params.row?.providerType?.toLowerCase()?.includes('epic')
                    ? '#007CCF'
                    : params.row?.providerType?.toLowerCase()?.includes('blue button')
                    ? '##292D32'
                    : '#FF0000'
                return (
                    <Stack direction="row" spacing={1.5} alignItems="center">
                        <ImportFolderIcon color={color} />
                        <Typography>{params.value}</Typography>
                    </Stack>
                )
            },
        },
        {
            field: 'providerName',
            headerName: 'Provider Name',
        },
        {
            field: 'profileName',
            headerName: 'Profile Name',
        },
        {
            field: 'remove',
            headerName: 'Action',
            flex: 0.5,
            sortable: { undefined }, // sortable:false
            disableColumnMenu: true,
            renderCell: (params) => (
                <div style={{ display: 'flex' }}>
                    {loadingEhr === params.row.ehrEndPointId ? (
                        <ReactLoading width={20} height={20} type="spokes" color="rgb(64 60 60)" />
                    ) : (
                        <CachedIcon
                            className="edit-icon"
                            onClick={() => {
                                if (!loadingEhr) refetchEHR(params.row)
                            }}
                        />
                    )}
                    <DeleteOutlinedIcon
                        className="close-icon"
                        onClick={() => {
                            if (!loadingEhr)
                                setState({
                                    ...state,
                                    open: true,
                                    delFacility: params.row.providerType,
                                    selectedProfileId: params.row.profileId,
                                })
                        }}
                    />
                </div>
            ),
        },
    ]

    return (
        <div className="col-lg-5">
            <div style={{ height: 500, width: '100%' }}>
                <div className="mb-2 d-lg-block">
                    <FormGroupHeader>Associated Providers List</FormGroupHeader>
                    <MrcTooltip text="All Provider's you've associated with your MRC EHR profile are listed below.">
                        {({ onOpen }) => (
                            <IconButton color="primary" aria-label="Info" onClick={onOpen}>
                                <NewToolTipIcon top={0} />
                            </IconButton>
                        )}
                    </MrcTooltip>
                </div>
                <DataGrid
                    pageSize={5}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    disableSelectionOnClick
                    rows={state.facilities}
                    getRowId={(r) => r.providerType}
                />
            </div>
            {/* Popup to confirm that patient wants to remove records from a certain provider */}
            <Dialog
                open={state.open}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Provider</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete provider?
                    </DialogContentText>
                </DialogContent>
                <DialogActions>
                    <Button color="primary" className="common-button" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        style={{}}
                        border-radius="2px"
                        color="primary"
                        className="common-button"
                        variant="contained"
                        onClick={deleteYourRecord}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
