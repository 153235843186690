import React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { useState } from 'react'
import { ImportButton } from 'components/BlueImportButton'
import { Box, TextField } from '@mui/material'
import CompassCalibrationOutlinedIcon from '@mui/icons-material/CompassCalibrationOutlined'
import { RecordsService } from 'services/myrecords.service'
import popupNotification from 'components/Notification/popupNotification'

const NotesModal = ({ title, isOpen, onClose, selectedProfileId, selectedTab, selectedNote }) => {
    const [note, setNote] = useState(selectedNote || '')

    const handleAdd = async () => {
        const payload = {
            profileId: selectedProfileId,
            recordTypeId: selectedTab.id,
            note,
        }
        try {
            const result = await RecordsService.saveRecordTypeNote(payload)
            console.log('result', result)
            popupNotification(`Note saved succesful!`, true)
        } catch (error) {
            popupNotification('Something went wrong while saving note', false)
        }

        //TODO: CALL API
        onClose('updated')
    }

    const handleChange = (event) => {
        if (event?.target.value.length <= 200) {
            setNote(event?.target.value)
        }
        if (event?.target.value.length > 200) {
            setNote(event?.target.value.substring(0, 200))
        }
    }

    return (
        <Dialog
            fullWidth
            maxWidth="sm"
            open={isOpen}
            onClose={onClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
        >
            <DialogTitle id="alert-dialog-title">{title}</DialogTitle>
            <DialogContent sx={{ p: 0 }}>
                <Box
                    sx={{
                        m: 2,
                        display: 'flex',
                        flexDirection: 'column',
                        alignItems: 'flex-start',
                        justifyContent: 'space-between',
                    }}
                >
                    <TextField
                        fullWidth
                        multiline
                        minRows={4}
                        id="note"
                        type="text"
                        label="Notes"
                        inputProps={{ readOnly: false }}
                        value={note}
                        onChange={handleChange}
                    />
                    <p>{note?.length}/200</p>
                </Box>
            </DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Cancel</Button>
                <ImportButton
                    startIcon={<CompassCalibrationOutlinedIcon />}
                    autoFocus
                    variant="contained"
                    color="primary"
                    onClick={handleAdd}
                >
                    Save
                </ImportButton>
            </DialogActions>
        </Dialog>
    )
}

export default NotesModal
