import React, { useState } from 'react'
import DataGrid from 'components/DataTable/DataGridWrapper'
import ShareMyRecordsCard from 'components/ShareMyRecordsCard'
import Share from 'components/Share/Share'
import CancelIcon from '@mui/icons-material/Cancel'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogTitle from '@mui/material/DialogTitle'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import { DialogContentText } from '@mui/material'
import './ShareMyRecords.scss'

const Organizations = ({ data, onRemove, onShare }) => {
    const [open, setOpen] = useState(false)
    const [organizationId, setOrganizationId] = useState(null)

    const handleOnRemove = (id) => {
        setOrganizationId(id)
        setOpen(true)
    }
    const handleCloseModal = () => {
        setOrganizationId(null)
        setOpen(false)
    }
    const columns = [
        {
            field: 'organizationName',
            headerName: 'Name',
            renderCell: (params) => params.row.organizationName,
        },

        {
            field: 'location',
            headerName: 'Location',
            renderCell: (params) => params.row.location,
        },
        {
            field: 'sharedProfile',
            headerName: 'Shared Profile Name',
            renderCell: (params) => {
                return (
                    <ul>
                        {params.row?.sharedProfile?.map((sharedProfile) => (
                            <li>{sharedProfile.name}</li>
                        ))}
                    </ul>
                )
            },
        },
        {
            field: 'share',
            headerName: 'Share',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <Share
                    data={params.row}
                    handleShareSave={(checkedMongoTables, selectedProfileIds) =>
                        onShare(checkedMongoTables, selectedProfileIds, params.row.organizationId)
                    }
                />
            ),
        },
        // Column with clickable icon for patient to delete organization
        {
            field: 'remove',
            headerName: 'Remove',
            sortable: false,
            disableColumnMenu: true,
            renderCell: (params) => (
                <CancelIcon className="close-icon" onClick={() => handleOnRemove(params.row.organizationId)} />
            ),

            // renderCell: (params) => <CancelIcon className="close-icon" />,
        },
    ]
    return (
        <>
            <div style={{ height: 500, width: '100%' }}>
                <DataGrid
                    pageSize={10}
                    columns={columns}
                    disableColumnFilter
                    disableColumnSelector
                    rows={data}
                    disableSelectionOnClick
                    getRowId={(r) => r.organizationId}
                    renderRowMobile={(row) => (
                        <ShareMyRecordsCard
                            type="organization"
                            row={row}
                            onChat={() => {}}
                            onBook={() => {}}
                            onShare={(checkedMongoTables, selectedProfileIds) =>
                                onShare(checkedMongoTables, selectedProfileIds, row.organizationId)
                            }
                            onRemove={(row) => handleOnRemove(row.organizationId)}
                            // onChat={this.handleOnChat}
                            // onShare={this.handleOnShare}
                            // onBook={this.handleOnBook}
                            // onRemove={this.handleOnRemove}
                        />
                    )}
                />
            </div>
            <Dialog
                open={open}
                onClose={handleCloseModal}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">Delete Organization</DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        Are you sure you want to delete organization?
                    </DialogContentText>
                </DialogContent>

                <DialogActions>
                    {/* Cancel deletion */}
                    <Button onClick={handleCloseModal} className="text-uppercase">
                        Cancel
                    </Button>

                    {/* Confirm deletion */}
                    <Button
                        color="primary"
                        variant="contained"
                        className="common-button"
                        onClick={() => onRemove(organizationId)}
                    >
                        Delete
                    </Button>
                </DialogActions>
            </Dialog>
        </>
    )
}

export default Organizations
