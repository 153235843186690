import React from 'react'
import DataGrid from 'components/DataTable/DataGridWrapper'

import Loader from 'components/Loader/Loader'
import { CarePlanProfileIdAPI } from 'services/Services'
import { useSearchField } from 'hoc/SearchFieldProvider'
import filterEntries from 'util/filterEntries'
import AlertDialog from 'components/AlertDialog'
import { ToolTipIcon } from 'assets/svg/tooltipIcon'
import { IconButton } from '@mui/material'

class TreatmentPlanImpl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            loading: true,
            open: false,
            selected: {},
        }
    }

    componentDidMount() {
        const { setSearchColumns, selectedDependent } = this.props
        this.carePlanProfileId(selectedDependent?.id)
        setSearchColumns([
            {
                value: 'providerName',
                label: 'Provider name',
            },
            {
                value: 'status',
                label: 'Status',
            },
            {
                value: 'patient',
                label: 'Patient',
            },
            {
                value: 'addresses',
                label: 'Addresses',
            },
            {
                value: 'activity',
                label: 'Activity',
            },
        ])
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedDependent } = this.props
        if (selectedDependent?.id !== prevProps.selectedDependent?.id) {
            this.carePlanProfileId(selectedDependent?.id)
        }
    }

    // Function to retrieve patient care plans from Matchrite API
    carePlanProfileId = async (selectedDependentId) => {
        let profileId = selectedDependentId || localStorage.getItem('profileid')
        this.setState({
            loading: true,
        })
        const data = await CarePlanProfileIdAPI(profileId)

        if (data) {
            if (data.message === 'No Data Found') {
                this.setState({ value: [], loading: false })
            } else {
                this.setState({ value: data.carePlan, loading: false })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    getFilteredEntries = () => {
        const { value } = this.state
        const { searchTerm, filterByColumn } = this.props
        const allColumns = ['providerName', 'status', 'patient', 'addresses', 'activity']
        const columns = filterByColumn === 'all' ? allColumns : [filterByColumn]
        return filterEntries(value, columns, searchTerm)
    }

    render() {
        if (this.state.loading) {
            return <Loader pageName="treatmentplan" />
        }

        // Columns for data grid that will that will display patient's care plans
        const columns = [
            {
                field: 'providerName',
                headerName: 'Provider name',
                flex: 0.5,
            },
            {
                field: 'patient',
                headerName: 'Patient Name',
                flex: 0.5,
                // renderCell: (param) => param.value?.display,
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 0.3,
            },
            {
                field: 'addresses',
                headerName: 'Addresses',
                flex: 0.7,
            },
            // {
            //     field: '',
            //     headerName: 'Activity',
            //     flex: 0.3,
            //     renderCell: (params) => params.row.activity?.map((activity) => activity.actionResulting).join(','),
            // },
            {
                field: '',
                headerName: 'Click icon for details',
                // headerName: 'Activity',
                flex: 0.2,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (param) => (
                    // Icon clicked to display consolidated information about data row
                    <IconButton
                        onClick={() =>
                            this.setState({
                                selected: param.row,
                                open: true,
                            })
                        }
                    >
                        <ToolTipIcon />
                    </IconButton>
                ),
            },
        ]

        return (
            <div className="container-right">
                {/* Data grid that populates cells with patient care plan data (limit 5 rows per page)*/}
                <div className="white-container">
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            rows={this.getFilteredEntries()}
                            disableSelectionOnClick
                            getRowId={(r) => JSON.stringify(r)}
                        />
                    </div>
                    {/* Popup that displays information about a lab row when the icon in it's furthest right column is clicked */}
                    {Object.keys(this.state.selected).length > 0 && (
                        <AlertDialog
                            title="Care Plan details"
                            fullWidth
                            open={this.state.open}
                            setOpen={(value) => this.setState({ open: value })}
                        >
                            {/* <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <label className="fw-bold">Provider name:</label> {this.state.selected.providerName}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Patient Name:</label> {this.state.selected.patient}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Performer:</label> {this.state.selected.performer}
                            </div> */}
                            {this.state.selected.activity?.map((activity) => (
                                <>
                                    <div style={{ marginBottom: '20px' }}>
                                        <label className="fw-bold">Care Team:</label> {activity?.detail?.careTeam || ''}
                                    </div>
                                    <div style={{ marginBottom: '20px' }}>
                                        <label className="fw-bold">Kind:</label> {activity?.detail?.kind || ''}
                                    </div>
                                    <div style={{ marginBottom: '20px' }}>
                                        <label className="fw-bold">Code:</label> {activity?.detail?.code?.text} -{' '}
                                        {activity?.detail?.code?.coding}
                                    </div>
                                    <div style={{ marginBottom: '20px' }}>
                                        <label className="fw-bold">Scheduled :</label>{' '}
                                        {activity?.detail?.scheduled || ''}
                                    </div>
                                    <div style={{ marginBottom: '20px' }}>
                                        <label className="fw-bold">Status :</label> {activity?.detail?.status || ''}
                                    </div>
                                </>
                            ))}
                            {/* <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Effective date:</label>{' '}
                                {this.state.selected.effectiveDateTime
                                    ? getDate(this.state.selected.effectiveDateTime)
                                    : ''}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Issued:</label>{' '}
                                {this.state.selected.issued ? getDate(this.state.selected.issued) : ''}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Status:</label> {this.state.selected.status}
                            </div> */}
                        </AlertDialog>
                    )}
                </div>
            </div>
        )
    }
}

export function TreatmentPlan(props) {
    const { searchTerm, filterByColumn, setSearchColumns, selectedDependent } = useSearchField()

    return (
        <TreatmentPlanImpl
            {...props}
            searchTerm={searchTerm}
            filterByColumn={filterByColumn}
            setSearchColumns={setSearchColumns}
            selectedDependent={selectedDependent}
        ></TreatmentPlanImpl>
    )
}

export default TreatmentPlan
