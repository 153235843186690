import React from 'react'
import DataGrid from 'components/DataTable/DataGridWrapper'

import { getDate } from 'use/Helper'
import Loader from 'components/Loader/Loader'
import AlertDialog from 'components/AlertDialog'
import { ImmunizationByProfileIdAPI } from 'services/Services'
import { useSearchField } from 'hoc/SearchFieldProvider'
import filterEntries from 'util/filterEntries'
import { ToolTipIcon } from 'assets/svg/tooltipIcon'
import { IconButton } from '@mui/material'

class ImmunizationImpl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            cerner: '',
            open: false,
            selected: {},
            loading: true,
        }
    }

    componentDidMount() {
        const { setSearchColumns, selectedDependent } = this.props
        this.immunizationByProfileId(selectedDependent?.id)
        setSearchColumns([
            {
                value: 'providerName',
                label: 'Provider name',
            },
            {
                value: 'resourceType',
                label: 'Resource type',
            },
            {
                value: 'patient',
                label: 'Patient',
            },
            {
                value: 'vaccineCode',
                label: 'Vaccine Code',
            },
            {
                value: 'status',
                label: 'Status',
            },
            {
                value: 'date',
                label: 'Date',
            },
        ])
    }
    componentDidUpdate(prevProps, prevState) {
        const { selectedDependent } = this.props
        if (selectedDependent?.id !== prevProps.selectedDependent?.id) {
            this.immunizationByProfileId(selectedDependent?.id)
        }
    }

    // Function to retrieve patient immunizations from Matchrite API
    immunizationByProfileId = async (selectedDependentId) => {
        let profileid = selectedDependentId || localStorage.getItem('profileid')
        let cerner = localStorage.getItem('cerner')
        this.setState({
            loading: true,
        })
        const data = await ImmunizationByProfileIdAPI(profileid)

        // Get test user records
        // const data = await ImmunizationByProfileIdAPI(135)

        if (cerner === -1) {
            return <div>EHR</div>
        } else {
            if (data) {
                if (data.message === 'No Data Found') {
                    this.setState({ value: [], loading: false })
                } else {
                    this.setState({
                        value: data.immunization,
                        loading: false,
                    })
                }
            } else {
                this.setState({ loading: false })
            }
        }
    }

    getFilteredEntries = () => {
        const { value } = this.state
        const { searchTerm, filterByColumn } = this.props
        const allColumns = ['providerName', 'resourceType', 'patient', 'vaccineCode', 'date', 'status']
        const columns = filterByColumn === 'all' ? allColumns : [filterByColumn]
        return filterEntries(value, columns, searchTerm)
    }

    render() {
        if (this.state.loading) {
            return <Loader pageName="immunization" />
        }

        // Columns for data grid that will that will display patient's documented immunizations
        const columns = [
            {
                field: 'providerName',
                headerName: 'Provider name',
                flex: 0.5,
            },
            {
                field: 'resourceType',
                headerName: 'Resource type',
                flex: 0.5,
            },
            {
                field: 'patient',
                headerName: 'Patient',
                flex: 0.5,
            },
            {
                field: 'vaccineCode',
                headerName: 'Vaccine Code',
                flex: 0.5,
            },
            {
                field: 'date',
                headerName: 'Date',
                type: 'date',
                flex: 0.5,
                valueFormatter: (params) => getDate(params.value),
                renderCell: (param) => (param.value ? getDate(param.value) : ''),
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 0.5,
            },
            {
                field: '',
                headerName: 'Click icon for details',
                flex: 0.2,
                sortable: false,
                disableColumnMenu: true,
                // Icon clicked to display consolidated information about data row
                renderCell: (param) => (
                    <IconButton
                        onClick={() =>
                            this.setState({
                                selected: param.row,
                                open: true,
                            })
                        }
                    >
                        <ToolTipIcon />
                    </IconButton>
                ),
            },
        ]

        return (
            <div className="container-right">
                {/* Data grid that populates cells with patient immunization data (limit 5 rows per page)*/}
                <div className="white-container">
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            rows={this.getFilteredEntries()}
                            disableSelectionOnClick
                            getRowId={(r) => JSON.stringify(r)}
                        />
                    </div>

                    {/* Popup that displays information about a immunization row when the icon in it's furthest right column is clicked */}
                    {Object.keys(this.state.selected).length > 0 && (
                        <AlertDialog
                            fullWidth
                            title="Immunization details"
                            open={this.state.open}
                            setOpen={(value) => this.setState({ open: value })}
                        >
                            <div style={{ marginBottom: '20px', marginTop: '20px' }}>
                                <label className="fw-bold">Provider name:</label> {this.state.selected.providerName}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Resource type:</label> {this.state.selected.resourceType}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Status:</label> {this.state.selected.status}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Vaccine code:</label> {this.state.selected.vaccineCode}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Patient:</label> {this.state.selected.patient}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Location:</label> {this.state.selected.location}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Performer:</label> {this.state.selected.performer}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Dose quantity value:</label>{' '}
                                {this.state.selected.doseQuantityvalue}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Dose quantity unit:</label>{' '}
                                {this.state.selected.doseQuantityunit}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Encounter:</label> {this.state.selected.encounter}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Date:</label> {this.state.selected.date}
                            </div>
                            <div style={{ marginBottom: '20px' }}>
                                <label className="fw-bold">Route:</label> {this.state.selected.route}
                            </div>
                        </AlertDialog>
                    )}
                </div>
            </div>
        )
    }
}

export function Immunization(props) {
    const { searchTerm, filterByColumn, setSearchColumns, selectedDependent } = useSearchField()

    return (
        <ImmunizationImpl
            {...props}
            searchTerm={searchTerm}
            filterByColumn={filterByColumn}
            setSearchColumns={setSearchColumns}
            selectedDependent={selectedDependent}
        ></ImmunizationImpl>
    )
}

export default Immunization
