import React from 'react'
import DataGrid from 'components/DataTable/DataGridWrapper'

import { getDate } from 'use/Helper'
import Loader from 'components/Loader/Loader'
import AlertDialog from 'components/AlertDialog'
import { ObservationByProfileIdAPI } from 'services/Services'
import { useSearchField } from 'hoc/SearchFieldProvider'
import filterEntries from 'util/filterEntries'
import { ToolTipIcon } from 'assets/svg/tooltipIcon'
import { IconButton } from '@mui/material'

class VitalsImpl extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            value: [],
            open: false,
            selected: {},
            loading: true,
        }
    }

    componentDidMount() {
        const { setSearchColumns, selectedDependent } = this.props
        this.observationByProfileId(selectedDependent?.id)
        setSearchColumns([
            {
                value: 'providerName',
                label: 'Provider name',
            },
            {
                value: 'status',
                label: 'Status',
            },
            {
                value: 'category',
                label: 'Category',
            },
            {
                value: 'observation',
                label: 'Observation',
            },
            {
                value: 'effectiveDateTime',
                label: 'Date',
            },
        ])
    }

    componentDidUpdate(prevProps, prevState) {
        const { selectedDependent } = this.props
        if (selectedDependent?.id !== prevProps.selectedDependent?.id) {
            this.observationByProfileId(selectedDependent?.id)
        }
    }

    // Retrieves patient vitals from Matchrite API
    observationByProfileId = async (selectedDependentId) => {
        let profileId = selectedDependentId || localStorage.getItem('profileid')
        this.setState({
            loading: true,
        })
        const data = await ObservationByProfileIdAPI(profileId)

        // Get test user records
        // const data = await ObservationByProfileIdAPI(135)

        if (data) {
            if (data.message === 'No Data Found') {
                this.setState({ value: [], loading: false })
            } else {
                this.setState({ value: data.observation, loading: false })
            }
        } else {
            this.setState({ loading: false })
        }
    }

    getFilteredEntries = () => {
        const { value } = this.state
        const { searchTerm, filterByColumn } = this.props
        const allColumns = ['providerName', 'status', 'category', 'observation', 'issued', 'effectiveDateTime']
        const columns = filterByColumn === 'all' ? allColumns : [filterByColumn]
        return filterEntries(value, columns, searchTerm)
    }

    render() {
        if (this.state.loading) {
            return <Loader pageName="vitals" />
        }

        // Columns for data grid that will that will display patient's vitals
        const columns = [
            {
                field: 'providerName',
                headerName: 'Provider name',
                flex: 0.7,
            },
            {
                field: 'patient',
                headerName: 'Patient Name',
                flex: 0.3,
            },
            {
                field: 'status',
                headerName: 'Status',
                flex: 0.3,
            },
            {
                field: 'category',
                headerName: 'Category',
                flex: 0.5,
            },
            {
                field: 'code',
                headerName: 'Code',
                flex: 0.5,
            },
            // {
            //     field: 'observation',
            //     headerName: 'Observation',
            //     flex: 0.7,
            // },
            // {
            //     field: 'issued',
            //     headerName: 'Issued date',
            //     type: 'date',
            //     flex: 0.5,
            //     renderCell: (param) => (param.value ? getDate(param.value) : ''),
            // },
            {
                field: 'effectiveDateTime',
                headerName: 'Effective date',
                type: 'date',
                flex: 0.5,
                renderCell: (param) => (param.value ? getDate(param.value) : ''),
            },
            {
                field: '',
                headerName: 'Click icon for details',
                // headerName: 'Click info bullet(s) below to display record details',
                // headerName: '',
                flex: 0.2,
                sortable: false,
                disableColumnMenu: true,
                renderCell: (param) => (
                    // Icon clicked to display consolidated information about data row
                    <IconButton
                        onClick={() =>
                            this.setState({
                                selected: param.row,
                                open: true,
                            })
                        }
                    >
                        <ToolTipIcon />
                    </IconButton>
                ),
            },
        ]

        return (
            <div className="container-right">
                {/* Data grid that populates cells with patient vitals data (limit 5 rows per page)*/}
                <div className="white-container">
                    <div style={{ height: 500, width: '100%' }}>
                        <DataGrid
                            columns={columns}
                            disableColumnFilter
                            disableColumnSelector
                            rows={this.getFilteredEntries()}
                            disableSelectionOnClick
                            getRowId={(r) => JSON.stringify(r)}
                        />
                    </div>

                    {/* Popup that displays information about a vital's row when the icon in it's furthest right column is clicked */}
                    {Object.keys(this.state.selected).length > 0 && (
                        <AlertDialog
                            fullWidth
                            title="Vital Details"
                            open={this.state.open}
                            setOpen={(value) => this.setState({ open: value })}
                        >
                            {/* <div>
                                <label className="fw-bold">Provider name:</label> {this.state.selected.providerName}
                            </div> */}
                            <div style={{ marginBottom: '10px' }}>
                                {this.state.selected.category === 'Social History' ? (
                                    <div>
                                        {this.state.selected.socialHistoryResult ? (
                                            <span>
                                                <label className="fw-bold">Social History Result: </label>{' '}
                                                {this.state.selected.socialHistoryResult || '-'}
                                            </span>
                                        ) : (
                                            <span>
                                                <label className="fw-bold">Data Absent Reason: </label>{' '}
                                                {this.state.selected.dataAbsentReason || '-'}
                                            </span>
                                        )}
                                    </div>
                                ) : (
                                    this.state.selected.value?.map((value) => (
                                        <div>
                                            <label className="fw-bold">Name: </label> {value.text}
                                            {'  '}
                                            <label className="fw-bold">Value: </label> {value.value}{' '}
                                            <label className="fw-bold">Code:</label> {value.code}
                                        </div>
                                    ))
                                )}
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <label className="fw-bold">Status:</label> {this.state.selected.status}
                            </div>
                            {/* <div>
                                <label className="fw-bold">Category:</label> {this.state.selected.category}
                            </div>
                            <div>
                                <label className="fw-bold">Observation:</label> {this.state.selected.observation}
                            </div> */}
                            {/* <div>
                                <label className="fw-bold">Value:</label>{' '}
                                <ul>
                                    {this.state.selected.value?.map((value) => (
                                        <li>{value.value}</li>
                                    ))}
                                </ul>
                            </div> */}
                            <div style={{ marginBottom: '10px' }}>
                                <label className="fw-bold">Encounter:</label>{' '}
                                {/* <ul>
                                    {this.state.selected.value?.map((value) => (
                                        <li>{value.text}</li>
                                    ))}
                                </ul> */}
                                {this.state.selected.encounter || '-'}
                                {/* {this.state.selected.value?.map((value) => value.text).join(',')} */}
                            </div>
                            <div style={{ marginBottom: '10px' }}>
                                <label className="fw-bold">Interpretation:</label>{' '}
                                {this.state.selected.interpretation || '-'}
                            </div>
                            {/* <div>
                                <label className="fw-bold">Issued date:</label>{' '}
                                {this.state.selected.issued ? getDate(this.state.selected.issued) : ''}
                            </div>
                            <div>
                                <label className="fw-bold">Effective date:</label>{' '}
                                {this.state.selected.effectiveDateTime
                                    ? getDate(this.state.selected.effectiveDateTime)
                                    : ''}
                            </div> */}
                        </AlertDialog>
                    )}
                </div>
            </div>
        )
    }
}

export function Vitals(props) {
    const { searchTerm, filterByColumn, setSearchColumns, selectedDependent } = useSearchField()
    return (
        <VitalsImpl
            {...props}
            searchTerm={searchTerm}
            filterByColumn={filterByColumn}
            setSearchColumns={setSearchColumns}
            selectedDependent={selectedDependent}
        ></VitalsImpl>
    )
}

export default Vitals
